import React, { useContext, useEffect, useState } from 'react'
import NewsOrDeclarItem from './NewsOrDeclarItem'
import styles from './newsOrDec.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function NewsOrDeclar({category, children}) {
    const [arr, setArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateed, setUpdated] = useState(false)
    const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)
    const limit = 20

    let lang = translate[selectedLang]

    useEffect(() => {
      setOffset(0)
      setUpdated(true)
    }, [])

    useEffect(() => {
      (async() => {
          setOffset(0)
          await new Promise(res => {setArr([]); setTimeout(res(), 0)})
          setLoading(true)
          const res = await axiosInstance.get(`/use/${category}?offset=${0}&limit=${limit}`)
          if(res.data.status){
            setArr([...res.data.data])
            if(res.data.data.length == 20){
              setOffsetUpdated(false)
            }
          }else{
            setArr([])
          }
          setLoading(false)
      })()
    },[category])


    useEffect(() =>{
      (async() => {
        if(updateed && offset){
          setLoading(true)
          const res = await axiosInstance.get(`/use/${category}?offset=${offset}&limit=${limit}`)
          if(res.data.status){
            setArr([...arr,...res.data.data])
            if(res.data.data.length == 20){
              setOffsetUpdated(false)
            }
          }else{
            setArr([...arr])
          }
          setLoading(false)
        }
      })()
    }, [offset, updateed])

  return (
    <div className={styles['news-declaration-main']}> 
        <div>
            <div className={styles['news-declaration-category']}>{category !== "news" ? lang.tr_announcements : lang.tr_news}</div> 
            <div className={category !== "news" ? styles['declaration-list-size'] : ""}>    
                <div className={category !== "news" ? styles['declaration-list'] : styles['news-list']}>
                  {arr.length ? (
                    arr.map(el => (
                        <NewsOrDeclarItem el={el} key={el._id} category={category}/>
                    ))
                  ) :(
                    !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null 
                  )}
                    {loading ? <Loading width={200}/> : ""}
                </div>
            </div> 
        </div>
    </div>
  )
}
