import React, { useContext, useEffect, useState } from 'react'
import ProductItem from './ProductItem'
import styles from './product.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'
import { useSearchParams } from 'react-router-dom'

export default function Products({children}) {
    const [arr, setArr] = useState([])
    const [filter, setFilter] = useState("")
    const [loading, setLoading] = useState(false)
    const [updateed, setUpdated] = useState(false)
    const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)
    const limit = 20

    let lang = translate[selectedLang]

    const [searchParams] = useSearchParams()

    useEffect(() => {
      const filter = searchParams.get("category")
      if(filter){
        setFilter(filter)
      }else{
        setFilter("all")
      }
    }, [searchParams.get("category")])

    useEffect(() => {
      setOffset(0)
      setUpdated(true)
    }, [filter])


    useEffect(() => {
      setOffset(0)
      setUpdated(true)
    }, [])

    useEffect(() =>{
      (async() => {
        if(offset > 0){
        if(updateed){
          setLoading(true)
          const res = await axiosInstance.get(`/use/products?category=${filter}&offset=${offset}&limit=${limit}`)
          if(res.data.status){
            setArr([...arr,...res.data.data])
            if(res.data.data.length == 20){
              setOffsetUpdated(false)
            }
          }else{
            setArr([...arr])
          }
          setLoading(false)
        }
      }
      })()
    }, [offset, updateed])

    useEffect(() =>{
      (async() => {
        if(filter){
          setArr([])
            setLoading(true)
            const res = await axiosInstance.get(`/use/products?category=${filter}&offset=${offset}&limit=${limit}`)
            if(res.data.status){
              setArr([...res.data.data])
              if(res.data.data.length == 20){
                setOffsetUpdated(false)
            }}else{
              setArr([])
            }
            setLoading(false)
        }
      })()
    }, [filter])

  return (
    <div>
      <div className={styles['headers-main']}>
        {filter == "all" ? <div className={styles['header']}>Header-Products</div> :
        filter == "crypto" ? <div className={styles['header']}>Header-Crypto</div> :
        <div className={styles['header']}>Header-Shooting</div>}
      </div>
      <div className={styles['products-main']}> 
          <div className={styles['products-size']}>    
              <div className={styles['products-category']}>{filter == "all" ? lang.tr_product : filter == "crypto" ? "Ծածկագրային սարքեր" : "Կրակային վարժասարքեր"}</div> 
              <div className={styles['filters']}>
                {/* <div className={filter == "all" ? `${styles['filter']} ${styles['active']}` : styles['filter']} onClick={() => setFilter("all")}>Բոլորը</div>
                <div className={filter == "cripto" ? `${styles['filter']} ${styles['active']}` : styles['filter']} onClick={() => setFilter("cripto")}>Ծածկագրային սարքեր</div>
                <div className={filter == "simulator" ? `${styles['filter']} ${styles['active']}` : styles['filter']} onClick={() => setFilter("simulator")}>Կրակային վարժասարքեր</div> */}
              </div>
              <div className={styles['products-list']}>
                {arr.length ? (
                  arr.map(el => (
                      <ProductItem el={el} key={el._id}/>
                  ))
                ):(
                  !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null 
                )}
                  {loading ? <Loading width={200}/> : ""}
              </div>
          </div>  
      </div>
    </div>
  )
}
