import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import TextEditor from '../../../../components/TextEditor'
import styles from './edit.module.css'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function EditPartners({add}) {
    const [partner, setPartner] = useState({})
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const [link, setLink] = useState("")
    const [information, setInformation] = useState('')
    const [language, setLenguage] = useState("")
    const [fromLang, setFromLang] = useState(false)

    const {id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if(!add){
                const res = await axiosInstance.get(`/use/partner/${id}`)
                if(res.data.status){
                    setPartner(res.data.data)
                    setImage(res.data.data.image)
                    setLink(res.data.data.website)
                }
            }
            setLenguage("arm")
            })()
    },[])

    useEffect(() => {
        if( name !== "" && information !== '' && information !== '<p></p>\n'){
            setPartner(prev => ({...prev, [`${language}name`]: name, [`${language}information`]: information }))
        }
    }, [information, name])

    useEffect(() => {
        if(language){
            if(!add){
                setFromLang(true)
                setName(partner[`${language}name`])
                setInformation(partner[`${language}information`])
            }else{
                setName("")
                setInformation("")
            }
        }
    }, [language])


    const changeLanguage = async () => {
        if(name && information){
            if(language == "arm"){
               setLenguage("ru")
            }
            if(language == "ru"){
                setLenguage("eng")
            }
        }else{
            return 
        }
    }

    useEffect(() => {
        setPartner(prev => {return {...prev, image}})
    }, [image])

    useEffect(() => {
        setPartner(prev => {return {...prev, website: link}})
    }, [link])

    const addPartner = async () => {
        await axiosInstance.post(`/admin/partners/add`, partner)
        navigate("/dashboard/partners")
    }

    const editPartner = async () => {
        await axiosInstance.post(`/admin/partners/edit`, partner)
        navigate("/dashboard/partners")
    }

  return (
    <div>
        <div className={styles['edit-part']}>
            <h2>{language == "arm" ? "Հայերեն" : language == "ru" ? "Ռուսերեն" : "Անգլերեն"}</h2>
            <div className={styles['upload']}>
                <p>Ներբեռնել գործընկերոջ լոգոն։ </p>
                <FileUpload setImage={setImage}/>  
                <input disabled={true} placeholder='ֆայլ' value={image}/>
            </div>
            <div className={styles['info']}>
                <p>Գործընկերոջ անվանում։ </p>
                <input type='text' placeholder='Անվանում' value={name} onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className={styles['info']}>
                <p>Գործընկերոջ կայք։ </p>
                <input type='text' placeholder='Հղում կայքին' value={link} onChange={(e) => setLink(e.target.value)}/>
            </div>
            <div>
                <p>Գործընկերոջ մասին: </p>
                <TextEditor setInformation={setInformation} information={information} fromLang={fromLang} setFromLang={setFromLang}/>
            </div>
            <div className={styles['btn-div']}>
                {language != "eng" ? 
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={changeLanguage}>Հաջորդ</button> 
                    : !name || !information || !image || information == '<p></p>\n' ? <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
                    :
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={add ? addPartner : editPartner}>Հաստատել</button>}
                <Link to='/dashboard/partners'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
            </div>
        </div>
    </div>
  )
}
