import React, { useEffect, useState } from 'react'

 export const store = React.createContext()

export default function State({children}) {
  const [showMenu, setShowMenu] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [offset, setOffset] = useState(0)
  const [offsetUpdated, setOffsetUpdated] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [client, setClient] = useState()


  let initialLang = localStorage.getItem("langFromStorage") || "arm";

  const [selectedLang, setSelectedLang] = useState(initialLang);


  useEffect(() => {
     localStorage.setItem("langFromStorage", selectedLang)
  }, [selectedLang]);

  function langChange(value) {
      setSelectedLang(value)
      }

  const mainState = {
      showMenu,
      setShowMenu,
      selectedCategory,
      setSelectedCategory,
      selectedLang,
      langChange,
      offset,
      setOffset,
      offsetUpdated,
      setOffsetUpdated,
      scrollPosition,
      setScrollPosition,
      setClient,
      client
  }

  return (
    <store.Provider value={mainState}>
        {children}
    </store.Provider>
  )
}
