import React, { useContext } from 'react'
import styles from './mainAndFooter.module.css'
import { store } from '../HOC/State'
import stylesContact from '../../Pages/Museum/museum.module.css'
import { translate } from '../../Pages/translate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpen, faLocationDot, faSquarePhone } from '@fortawesome/free-solid-svg-icons'

export default function Footer() {
  const {selectedLang} = useContext(store)
  let lang = translate[selectedLang]
  return (
    <div className={styles['footer-div']}>
        <div className={styles['map-div']}>
                {/* {menu.map(el => (
                    
                        <FooterMenu key={el.category} el={el} />
                ))} */}
              <div className={styles['gradient']}></div>
              <div className={styles['contact-div']}>
                <div className={stylesContact['contact-part-div']} style={{marginRight: window.innerWidth >= 900 ? "20px" : "0"}}>
                    <FontAwesomeIcon className={styles['contact-part-icon']} icon={faSquarePhone} /> 
                    <div className={styles['contacts']}>
                        <p style={{color: "white"}}>{lang.tr_telephone}</p>
                        <p>+37415 27 27 27</p>
                    </div>
                </div>
                <div className={stylesContact['contact-part-div']} style={{marginRight: window.innerWidth >= 900 ? "20px" : "0"}}>
                    <FontAwesomeIcon className={styles['contact-part-icon']} icon={faEnvelopeOpen} />
                    <div className={styles['contacts']}>
                      <p style={{color: "white"}}>{lang.tr_mail} </p>
                      <a href='mailto: info@mergelyan.com' style={{color: "white"}}>info@mergelyan.com</a>
                    </div>
                </div>
                <div className={stylesContact['contact-part-div']} style={{marginRight: window.innerWidth >= 900 ? "20px" : "0"}}>
                    <FontAwesomeIcon className={styles['contact-part-icon']} icon={faLocationDot} />
                    <div className={styles['contacts']}>
                        <p style={{color: "white"}}>{lang.tr_address} </p>
                        <p>{lang.tr_address2}</p>
                    </div>
                </div>
            </div>
            <div className={styles['social-media-full']}>
                  <div className={styles['social-media']}>
                    <img src='/instagram.png' alt="instagram"/>
                    <a href="https://instagram.com">Instagram</a>
                  </div>
                  <div className={styles['social-media']}>
                    <img src='/facebook.png' alt="facebook"/>
                    <a href="https://facebook.com">Facebook</a>
                  </div>
                  <div className={styles['social-media']}>
                    <img src='/linkedin.png' alt="linkedin"/>
                    <a href="https://linkedin.com">LinkedIn</a>
                  </div>
                </div>
        </div>
        <div className={styles['copyright-div']}>
            © Mergelyan, 2024. All Rights Reserved by YCRDI.
        </div>
    </div>
  )
}
