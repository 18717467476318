import React, { useContext } from 'react'
import CustomCart from '../../components/HOC/CustomCart'
import { Parser } from 'html-to-react'
import styles from './leaders.module.css'
import { BACKEND_URL } from '../../const/Constant'
import { store } from '../../components/HOC/State'

export default function Leader({el}) {
    const  {selectedLang} = useContext(store)

    const htmlParser = new Parser()
    
  return (
    
    <div className={styles['each-leader-div']}>
        <CustomCart>
            <div className={styles['leader']}>
                <div className={styles['leader-media-info']}>
                    <img  className={styles['leader-image']} src={BACKEND_URL + el.image} alt='person-image' />
                    <div className={styles['leader-info']}>
                        <p>{el[`${selectedLang}name`]}</p>
                        <p>{el.terms}</p>
                    </div>
                </div>
                <div className={`${styles['leader-main-info']} material`}>
                    {htmlParser.parse(el[`${selectedLang}information`]?.
                            replaceAll(`iframe width=\"auto\" height=\"auto\"`, `iframe width=\"${window.innerWidth > 700 ? 0.32*window.innerWidth : 0.66*window.innerWidth}\" height=\"${window.innerWidth > 700 ? 0.32*window.innerWidth/1.6 : 0.66*window.innerWidth/1.6}\" allowfullscreen`).
                            replaceAll("alt=\"undefined\" style=\"height: auto;width: auto\"/>", `alt=\"undefined\" "height=\"auto\" width=\"${window.innerWidth > 700 ? 0.32*window.innerWidth : 0.66*window.innerWidth}\"/>`))}
                </div>
            </div>
        </CustomCart>
    </div>
    
  )
}
