import React, { useContext, useEffect, useState } from 'react'
import Leader from './Leader'
import styles from './leaders.module.css'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import Loading from '../../components/Loading'
import { axiosInstance } from '../../const/axiosInstance'

export default function LeadersList({children}) {
  const [arr, setArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [updateed, setUpdated] = useState(false)
  const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)
  const limit = 20

  let lang = translate[selectedLang]

  useEffect(() => {
    setOffset(0)
    setUpdated(true)
  }, [])

  useEffect(() =>{
    (async() => {
      if(updateed){
        setLoading(true)
        const res = await axiosInstance.get(`/use/leaders?offset=${offset}&limit=${limit}`)
        if(res.data.status){
          setArr([...arr,...res.data.data])
          if(res.data.data.length == 20){
            setOffsetUpdated(false)
          }
        }else{
          setArr([...arr])
        }
        setLoading(false)
      }
    })()
  }, [offset, updateed])

  return (
    <div className={styles['leaders-list']}>
        <div className={styles['leaders-list-div']}>
            <p className={styles['leaders-category']}>{lang.tr_leaders}</p>
            {arr.length  ? (
              arr.map(el => <Leader key={el._id} el={el}/>)
            ):(
              !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null 
            )}
            {loading ? <Loading width={200}/> : ""}
        </div>
    </div>
  )
}
