import React, { useContext, useEffect, useState } from 'react'
import styles from './expo.module.css'
import ExpoPlaces from './ExpoPlaces'
import Soon from './Soon'
import Finished from './Finished'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import { BACKEND_URL } from '../../const/Constant'
import { axiosInstance } from '../../const/axiosInstance'

export default function Expo() {
  const [selected, setSelected] = useState("places")
  const [image, setImage] = useState('')
  const [showImage, setShowimage] = useState(false)

  const {selectedLang} = useContext(store)
  let lang = translate[selectedLang]

  useEffect(() => {
    (async () => {
        const res = await axiosInstance.get(`/use/expo/nearest`)
        if(res.data.status){
            setImage(res.data.data[0].image)
            setShowimage(true)
        }
    })()
  }, [])

  return (
    <div className={styles['hole-expo']}>
        <div className={styles['expo-soon-image-div']}>
            <img src="/expo.jpg" alt='expo-soon-image' className={styles['expo-soon-image']}/>
            {/* <div className={styles['expo-header']}><div>Կազմակեպեք Ձեր էքսպո-ցուցահանդեսները մեզ մոտ</div></div> */}
        </div>
        {/* <div className={styles['undermenues']}>
            <div className={selected === 'places' ? `${styles['menu-title']} ${styles['choosed']}` : styles['menu-title']} onClick={() => setSelected('places')}>
               <p>{lang.tr_expo}</p>
            </div>
            <div className={selected === 'future' ? `${styles['menu-title']} ${styles['choosed']}` : styles['menu-title']} onClick={() => setSelected('future')}>
                <p>{lang.tr_futureExhibition}</p>
            </div>
            <div className={selected === 'passed' ? `${styles['menu-title']} ${styles['choosed']}` : styles['menu-title']} onClick={() => setSelected('passed')}>
                <p>{lang.tr_pastExhibition}</p>
            </div>
        </div> */}
        {selected === 'places' ? <ExpoPlaces/> : selected === 'future' ? <Soon from='future'/> : <Finished from='passed'/>}
    </div>
  )
}
