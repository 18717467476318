import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router'

import Footer from './Footer'

import styles from './mainAndFooter.module.css'
import HeaderSide from '../Header/HeaderSide'
// import Menu from '../Menu/Menu'
import { store } from '../HOC/State'

export default function MainContent() {
  const {offset, setOffset, offsetUpdated, setOffsetUpdated, setScrollPosition, setClient} = useContext(store)
  const [atTop, setAtTop] = useState(true)

  let location = useLocation()

  useEffect(() => {
    let scroll = document.getElementById('main-content')
    scroll.scrollTop = 0
  }, [location])

  

  const changeOffset = e => {
      e.target.scrollTop < 50 ? setAtTop(true) : setAtTop(false)
      if(e.target.scrollTop + e.target.clientHeight + 300 + (0.2*window.innerWidth/1.7 * 2) > e.target.scrollHeight && !offsetUpdated){
          setOffset(offset + 20)
          setOffsetUpdated(true)
      }
      if(location.pathname === "/"){
        setScrollPosition(e.target.scrollTop)
        setClient(e.target.clientHeight)
      }
      }

  return (
    <div>
        <HeaderSide atTop={atTop}/>
        {/* <Menu /> */}
        <div className={location.pathname === "/" && atTop === true ? `${styles['main-div']} ${styles['home-page']}` : styles['main-div']} id='main-content' onScroll={changeOffset} >
            <Outlet/>
            <Footer />
        </div>
    </div>
  )
}
