import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { BACKEND_URL } from '../../const/Constant'
import styles from './rent.module.css'

export default function RentImages({images}) {

  function scrollCalc(point, slide){
    console.log(slide.scrollLeft + ": " + slide.scrollWidth)

    if(slide.scrollLeft % (point * window.innerWidth)){
      let counts = Math.ceil(slide.scrollLeft /( point * window.innerWidth))
      console.log(counts)
      return counts * point * window.innerWidth
    } else{
      return slide.scrollLeft
    }
  }
    const slideLeft = () => {
         let slide = document.getElementById('rent-item-slide')
         if(slide.scrollLeft !== 0){
           slide.scrollTo({
              left: window.innerWidth >= 900 ? scrollCalc(0.4, slide) - 0.4*window.innerWidth : scrollCalc(0.8, slide) - 0.8*window.innerWidth,
              behavior: "smooth"
           })
         } else {
          slide.scrollTo({
            left: slide.scrollWidth - (window.innerWidth >= 900 ? 0.4*window.innerWidth : 0.8*window.innerWidth),
            behavior: "auto"
          })
        }
      }
  
      const slideRight = () => {
        let slide = document.getElementById('rent-item-slide')
        if(Math.ceil(window.innerWidth >= 900 ? slide.scrollLeft + 0.4*window.innerWidth : slide.scrollLeft + 0.8*window.innerWidth) < slide.scrollWidth){
          slide.scrollTo({
             left: window.innerWidth >= 900 ? scrollCalc(0.4, slide) + 0.4*window.innerWidth  : scrollCalc(0.8, slide) + 0.8*window.innerWidth,
             behavior: "smooth"
          })
        } else {
         slide.scrollTo({
           left: 0,
           behavior: "auto"
         })
        }
    }
   
  return (
        <div className={styles['parent-div']}>
            <div  className={styles['image-slide-main']} id='rent-item-slide'>
                <div className={styles['control-div']}>
                    <div onClick={slideLeft} className={`${styles['left']} ${styles['control-btn']}`}>
                        <FontAwesomeIcon className={styles['control-icon']} icon={faAngleLeft} />
                    </div>
                    <div onClick={slideRight} className={`${styles['right']} ${styles['control-btn']}`}>
                        <FontAwesomeIcon className={styles['control-icon']} icon={faAngleRight} />
                    </div>
                </div>
                <div className={styles['image-div']}>
                    {images?.map((image,i) => (<img src={BACKEND_URL + image} alt='rent-item-image' key={i}/>))}
                </div>
            </div>
        </div>

  )
}
