import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FileUpload from './FileUpload'
import TextEditor from '../../../../components/TextEditor'
import styles from './edit.module.css'
import { axiosInstance } from '../../../../const/axiosInstance'

export default function EditProducts({add}) {
    const [category, setCategory] = useState("all")
    const [product, setProduct] = useState({})
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const [information, setInformation] = useState('')
    const [language, setLenguage] = useState("")
    const [fromLang, setFromLang] = useState(false)
    const [description, setDescription] = useState('')

    const {id} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if(!add){
                const res = await axiosInstance.get(`/use/product/${id}`)
                if(res.data.status){
                    setProduct(res.data.data)
                    setImage(res.data.data.image)
                    setCategory(res.data.data.category)
                }
            }
            setLenguage("arm")
            })()
    },[])

    useEffect(() => {
        setProduct(p => ({...p, category: category }))
    }, [category])

    useEffect(() => {
        if( name !== "" && information !== '' && information !== '<p></p>\n' && description !== ''){
            setProduct(prev => ({...prev, [`${language}name`]: name, [`${language}information`]: information, [`${language}description`] : description }))
        }
    }, [information, name, description])

    useEffect(() => {
        if(language){
            if(!add){
                setFromLang(true)
                setName(product[`${language}name`])
                setInformation(product[`${language}information`])
                setDescription(product[`${language}description`])
            }else{
                setName("")
                setInformation("")
                setDescription('')
            }
        }
    }, [language])


    const changeLanguage = async () => {
        if(name && information){
            if(language == "arm"){
               setLenguage("ru")
            }
            if(language == "ru"){
                setLenguage("eng")
            }
        }else{
            return 
        }
    }

    useEffect(() => {
        setProduct(prev => {return {...prev, image}})
    }, [image])

    const addProduct = async () => {
        await axiosInstance.post(`/admin/products/add`, product)
        navigate("/dashboard/products")
    }

    const editProduct = async () => {
        await axiosInstance.post(`/admin/products/edit`, product)
        navigate("/dashboard/products")
    }
  
  return (
    <div className={styles['edit-part']}>
        <h2>{language == "arm" ? "Հայերեն" : language == "ru" ? "Ռուսերեն" : "Անգլերեն"}</h2>
        <div className={styles['upload']}>
            <p>{add ? 'Ավելացնել' : 'Փոխել'} արտադրանքի նկարը։ </p>
            <FileUpload setImage={setImage}/>  
            <input disabled={true} placeholder='ֆայլ' value={image}/>
        </div>
        <div className={styles['info']}>
            <p>Արտադրանքի անվանում։ </p>
            <input type='text' placeholder='Արտադրանքի անվանում' value={name} onChange={(e) => setName(e.target.value)}/>
        </div>
        <div className={styles['info']}>
            <p>Արտադրանքի հակիրճ նկարագիր։ </p>
            <textarea type='text' placeholder='Արտադրանքի հակիրճ նկարագիր' value={description} onChange={(e) => setDescription(e.target.value)}/>
        </div>
        <div>
            <p>Ընտրել բաժինը։ </p>
            <select value={category} onChange={e => setCategory(e.target.value)}>
                <option value="all">Բոլորը</option>
                <option value="crypto">Ծածկագրային սարքեր</option>
                <option value="simulator">Կրակային վարժասարքեր</option>
            </select>
        </div>
        <div>
            <p>Արտադրանքի մասին: </p>
            <TextEditor setInformation={setInformation} information={information} fromLang={fromLang} setFromLang={setFromLang}/>
        </div>
        <div className={styles['btn-div']}>
            {language != "eng" ? 
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={changeLanguage}>Հաջորդ</button> 
                    : !name || !information || !description || !image || information == '<p></p>\n' ? <button disabled className={`${styles['btn']} ${styles['confirm']}`}>Հաստատել</button>
                    :
                    <button className={`${styles['btn']} ${styles['confirm']}`} onClick={add ? addProduct : editProduct}>Հաստատել</button>}
            <Link to='/dashboard/products'><button className={`${styles['btn']} ${styles['cancel']}`}>Չեղարկել</button></Link>
        </div>
    </div>
  )
}
