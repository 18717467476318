import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styles from './mainAdmin.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'
import ExpoAdmin from './ExpoAdmin'
import { Parser } from 'html-to-react'
import { Link } from 'react-router-dom'
import SearchAd from './SearchAd'
import { axiosInstance } from '../../../const/axiosInstance'
import { BACKEND_URL } from '../../../const/Constant'

export default function AdminGetItems() {
    const {category} = useParams()
    const [search, setSearch] = useState('')
    const [arr, setArr] = useState([])
    const [history, setHistory] = useState({})

    const htmlParser = new Parser()

    useEffect(() => {
        (async () => {
            if(category != "expo"){
                const res = await axiosInstance.get(`/use/${category}`)
                if(res.data.status){
                    if(category == "history"){
                        setHistory(res.data.data)
                    }else{
                        setArr(res.data.data)
                    }
                }else{
                    setArr([])
                }
            }
        })()
    }, [category])


    const changeSearch = e => {
        setSearch(e.target.value)
    }

    const remove = async el => {
        await axiosInstance.delete(`/admin/${category}/remove/${el._id}`)
        setArr(arr.filter(item => item._id != el._id))
    }

  if(category != 'history' && category != 'expo' && category != 'library' && category != 'rent'){
    return (
        <div className={styles['element-div']}>
            <div className={styles['btn-search']}>
                <Link to='add'><button className={styles['btn']}>Ավելացնել</button></Link>
                <SearchAd search={search} changeSearch={changeSearch} />
            </div>
            <div className={styles['elements']}>
                {arr?.filter(el => el.armname?.includes(search)).map((el,i) => (
                    <div key={i} className={styles['element']}>
                        <p>
                            {el.armname}
                        </p>
                        <div className={styles['icons']}>
                            <Link to={`${el._id}`}><FontAwesomeIcon icon={faPenToSquare} className={styles['edit']}/> </Link>
                            <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el)}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
  )
}

if(category == 'history'){
    return ( 
        <div className={`${styles['history']} material`}>
            {htmlParser.parse(history.armtext?.
                            replaceAll(`iframe width=\"auto\" height=\"auto\"`, `iframe width=\"${0.6*window.innerWidth}\" height=\"${0.6*window.innerWidth/1.6}\" allowfullscreen`).
                            replaceAll("alt=\"undefined\" style=\"height: auto;width: auto\"/>", `alt=\"undefined\" "height=\"auto\" width=\"${0.7*window.innerWidth}\"/>`))}
            <div className={styles['icons']}>
                <Link to='edit'><FontAwesomeIcon icon={faPenToSquare} className={styles['edit']}/></Link>
            </div>
        </div>
    )
}

if(category == 'rent'){
    return (
    <div className={styles['element-div']}>
        <div className={styles['btn-search']}>
            <Link to='add'><button className={styles['btn']}>Ավելացնել</button></Link>
            <SearchAd search={search} changeSearch={changeSearch} />
        </div>
        <div className={styles['rent-hole-div']}>
            {arr.filter(el => el.arminformation?.includes(search)).map((el,id) => (
                <div key={id} className={styles['element']}>
                    <div className={styles['rent-div']}>
                        <img src={el.images ? BACKEND_URL + el.images[0] : ""} alt='element-image' className={styles['rent-image']}/>
                        <p>
                            {el.area}
                        </p>
                    </div>
                    <div className={styles['icons']}>
                        <Link to={`${el._id}`}><FontAwesomeIcon icon={faPenToSquare} className={styles['edit']}/> </Link>
                        <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el)}/>
                    </div>
                </div>
            ))}
        </div>
    </div>
    )
}

if(category == 'expo'){
    return <ExpoAdmin/>
}

if(category == 'library'){
    return(
        <div>
            <div className={styles['btn-search']}>
                <Link to='add'><button className={styles['btn']}>Ավելացնել</button></Link>
                <SearchAd search={search} changeSearch={changeSearch} />
            </div>
            <div className={styles['element-images-div']}>
            {arr.filter(el => el.image?.includes(search)).map((el, id) => (
                <div className={styles['element-image-div']} key={id}>
                    <img src={BACKEND_URL + el.image} alt='element-image' className={styles['element-image']}/>
                    <Link to={`${el._id}`}><FontAwesomeIcon icon={faPenToSquare} className={styles['edit']}/></Link>
                    <FontAwesomeIcon icon={faXmark} className={styles['remove']} onClick={() => remove(el)}/>
                </div>
            ))}
            </div>
        </div>
    )
}
}
