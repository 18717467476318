import React, { useContext, useEffect, useState } from 'react'
import MenuItem from './MenuItem'
import {menu} from './menuList'
import { store } from '../HOC/State'
import styles from './menu.module.css'

export default function MenuItems() {
  const [isOpen, setIsOpen] = useState(null)
  const [width, setWidth] = useState(window.innerWidth)
  const {showMenu,setShowMenu} = useContext(store)
  useEffect(
    () => {
      if(!showMenu){
        setIsOpen(null)
      }
    }, [showMenu])
  useEffect( () => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  return (
    <div className={width >= 1250 ? styles['menu-items-div'] : styles['mobile-menu-items-div']}>
        {
          menu.map((el, i) => <MenuItem key={i} el={el} isOpen={isOpen} setIsOpen={setIsOpen}/>)
        }
    </div>
  )
}
