import React, { useContext } from 'react'
import CustomCart from '../../components/HOC/CustomCart'
import { Parser } from 'html-to-react'
import styles from './coworkers.module.css'
import { store } from '../../components/HOC/State'
import { BACKEND_URL } from '../../const/Constant'

export default function CoworkersItem({el}) {
  const  {selectedLang} = useContext(store)

  const htmlParser = new Parser()
  return (
    <div className={styles['each-coworker-div']}>
        <CustomCart>
            <div className={styles['coworker']}>
                <div className={styles['coworker-media-info']}>
                    <img  className={styles['coworker-image']} src={BACKEND_URL + el.image} alt='person-image' />
                    <a href={el.website} target='_blank'>{el[`${selectedLang}name`]}</a>
                </div>
                <div className={`${styles['coworker-main-info']} material`}>
                    {htmlParser.parse(el[`${selectedLang}information`]?.
                            replaceAll(`iframe width=\"auto\" height=\"auto\"`, `iframe width=\"${window.innerWidth > 700 ? 0.32*window.innerWidth : 0.66*window.innerWidth }\" height=\"${window.innerWidth > 700 ? 0.32*window.innerWidth/1.6 : 0.66*window.innerWidth/1.6}\" allowfullscreen`).
                            replaceAll("alt=\"undefined\" style=\"height: auto;width: auto\"/>", `alt=\"undefined\" "height=\"auto\" width=\"${window.innerWidth > 700 ? 0.32*window.innerWidth : 0.66*window.innerWidth}\"/>`))}
                </div>
            </div>
        </CustomCart>
    </div>
  )
}
