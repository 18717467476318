import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './mainAdmin.module.css'

export default function AdminMenuItem({el}) {
    const url = useLocation().pathname


    if(!el.underCategory || el.category == "products"){
        return (
        <Link to={`/dashboard/${el.category}`} >
            {el.category != 'museum' && el.category != 'contacts' ?
            <div className={styles['menu-full-item-div'] }>
                <div className={styles['menu-item-name-div']} style={{background: url.includes(el.category) ? "rgba(128, 128, 128, 0.2)" : "transparent"}}>
                    <p>
                        {el.name.arm}
                    </p>
                </div>
            </div> : ""}
        </Link>
        )
    }else{
        return (
            <div>
                <div className={styles['menu-full-item-div'] }>
                    <div className={styles['menu-item-name-div']}>
                        <p>{el.name.arm}</p>
                    </div>
                </div>
                <div>
                    {el.underCategory.map((el,i) => <div key={i} className={styles['underCategory-items']}><AdminMenuItem el={el} /></div>)}
                </div>
            </div>
        )
    }
}
