import React, { useContext, useEffect, useRef, useState } from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'
import { Link } from 'react-router-dom'
import Cirlcles from '../components/Cirlcles'


export default function Home({children}) {
  let arr = [
    {
      image: "/SergeyMergelyan.jpg",
    },
    {
      image: "/mergelyan-building.jpeg",
    },
    {
      image: "/expo-building.jpeg",
    },
    {
      image: "/memorial.jpg",
    }
  ]
    
    const [currentIndex, setCurrentIndex] = useState(0)
    const [visible, setVisible] = useState(false)
    const [almost, setAlmost] = useState(0)
    const [about, setAbout] = useState(0)
    const [moreThen, setMoreThen] = useState(0)
    const {selectedLang, scrollPosition, client} = useContext(store)
    let lang = translate[selectedLang]

    const ref = useRef()
    const numbersRef = useRef(null)
    let timer = useRef()

    useEffect(() => {
      timer.current = setInterval(() => {
          setCurrentIndex(p => (p + 1) % arr.length)
        }, 8000)
       
      return () => clearInterval(timer.current)
    }, [])

    useEffect(() => {
      const objectElement = ref.current.querySelectorAll('object')

      const loaded = (e) => {
        const object = e.contentDocument
        if(object){
          const path = object.querySelectorAll("path")
          path.forEach((e) => e.setAttribute('fill', 'black'))
        }
      }

      objectElement.forEach(e => e.addEventListener('load', () => loaded(e)))

      return () => {
        objectElement.forEach(e => e.removeEventListener('load', () => loaded(e)))
      }
    }, [])

    function stopAnimation() {
      clearInterval(timer.current)
    }

    function continueAnimation(){
      timer.current = setInterval(() => {
        setCurrentIndex(p => (p + 1) % arr.length)
      }, 8000)
    }


  useEffect(() => {
    let rect = numbersRef.current?.getBoundingClientRect()

    if(rect && scrollPosition > 80){
      if(rect.top + rect.height < client)
      setVisible(true)
    }else{
      setVisible(false)
      setAlmost(0)
      setAbout(0)
      setMoreThen(0)
    }
  }, [scrollPosition])

  function numbersTimer(changeState, count){
    let picker = setInterval(() => {
      changeState(prevState => {
        if (prevState >= count) {
          clearInterval(picker); 
          return prevState;  
        }
        return prevState + 1;
      });
    }, 20);
    }

  useEffect(() => {
    if(visible){
      numbersTimer(setAlmost, 70)
      numbersTimer(setAbout, 100)
      numbersTimer(setMoreThen, 100)
    }
  }, [visible])


  return (
    <div>
      <div className={styles['home-div']} >
        <div>
            {/* <img src='/fon.webp' alt='main' className={styles['fon-image']}/> */}
            <div className={styles['on-animation']} >
                <div className={styles['name-h']}>
                  {/* <div className={styles['logo-image-div']}>
                    <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                  </div>
                  <div>
                      <h1 >{lang.tr_nameOfOrganization}</h1>
                      <h1>{lang.tr_nameOfOrganization2}</h1>
                  </div> */}
                  <h3 >
                     {lang.tr_slideText}
                  </h3>
                  <Link to="history">
                    <div className={styles['learn-more']}>
                      <div>{lang.tr_seeMore}</div>
                    </div>
                  </Link>
                  {/* <h3>
                     By being a forward-thinking company and leveraging its rich legacy,  YCRDI  is embracing new technologies and driving innovations to contribute to the military and industrial sectors. 
                  </h3> */}
                </div>

                {/* <div className={styles['image-back']}>
                    <img src='/mergelyan-building.jpg' alt="nkar"/>
                </div> */}
                <div className={styles['image-back']} style={{backgroundImage: `url(\"${arr[currentIndex].image}\")`, backgroundSize: "100% 100%", backgroundPosition: "top", transition: "background-image 0.5s ease-in-out"}} onMouseEnter={stopAnimation} onMouseLeave={continueAnimation}>
                    {/* <img src='/SergeyMergelyan.png' alt="nkar"/> */}
                    <div className={styles['circles']}>
                        {arr.map((_, i) => <Cirlcles index={i} key={i} current={currentIndex} setCurrentIndex={setCurrentIndex}/>)}
                    </div>
                </div>
               
            </div>
        </div>
        {/* <HomeServices /> */}
        {/* {children} */}
      </div>
      <div className={styles['offers']} >
        <h2>{lang.tr_weOffer}</h2>
        <div className={styles['offers-list']} ref={ref}>
          <Link to="/products?category=crypto" className={styles['offers-each']}>
              <p>{lang.tr_crypto1}</p>
              <p>{lang.tr_crypto2}</p>
              <object type="image/svg+xml" data="/crypto.svg" width="100" height="100" >
                  Ваш браузер не поддерживает отображение SVG.
              </object>
              {/* <img src="/crypto.svg" alt="crypto" /> */}
          </Link>
          <Link to="/products?category=simulator" className={styles['offers-each']}>
            <p>{lang.tr_shooting1}</p>
            <p>{lang.tr_shooting2}</p>
            <object type="image/svg+xml" data="/shooting.svg" width="100" height="100" >
                  Ваш браузер не поддерживает отображение SVG.
            </object>
            {/* <img src="/shooting.png" alt="shooting" /> */}
          </Link>
          <a href="https://meet.mergelyan.com/" target='_blank' className={styles['offers-each']}>
            <p>{lang.tr_conf1}</p>
            <p>{lang.tr_conf2}</p>
            <object type="image/svg+xml" data="/conf.svg" width="100" height="100" >
                  Ваш браузер не поддерживает отображение SVG.
            </object>
            {/* <img src="/conf.png" alt="conference" /> */}
          </a>
        </div>
      </div>
      <div className={styles['offers']} ref={numbersRef}>
        <h2>{lang.tr_numbers}</h2>
        <div className={styles['offers-list']} ref={ref}>
          <div className={styles['offers-each']}>
              <p className={styles['bolder']}>{lang.tr_almost}</p>
              <p>{almost}</p>
              <p>{lang.tr_years}</p>
              <p>{lang.tr_ofHistory}</p>
          </div>
          <div className={styles['offers-each']}>
            <p className={styles['bolder']}>{lang.tr_about}</p>
            <p>{about}</p>
            <p>{lang.tr_innovativeProducts}</p>
            <p>{lang.tr_throughoutHistory}</p>
          </div>
          <div  className={styles['offers-each']}>
            <p className={styles['bolder']}>{lang.tr_moreThan}</p>
            <p>{moreThen}</p>
            <p>{lang.tr_qualified}</p>
            <p>{lang.tr_specialists}</p>
          </div>
        </div>
      </div>
      {/* <ImagesSlide /> */}
    </div>
    
  )
}
