import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './product.module.css'
import { store } from '../../components/HOC/State'
import { BACKEND_URL } from '../../const/Constant'

export default function ProductItem({el}) {
  const  {selectedLang} = useContext(store)
  const id = el._id
  return (
    <div className={styles['products-item-1']}>
        {/* <div className={styles['products-item']}>
            <img src={BACKEND_URL + el.image} alt='element-image' className={styles['products-image']}/>
            <p className={styles['products-title']}>{el[`${selectedLang}name`]}</p>
        </div> */}
          <div className={styles['products-information-1']}>
              <p className={styles['products-title-1']}>{el[`${selectedLang}name`]}</p>
              <p className={styles['products-description-1']}>{el[`${selectedLang}description`]}</p>
              <Link to={`/products/${id}`}><button className={styles['see-more']}> See More </button></Link>
          </div>
          <div className={styles['products-image-div-1']}>
              <img src={BACKEND_URL + el.image} alt='element-image'/>
          </div>
      </div>
  )
}
