import React, { useContext, useEffect, useState } from 'react'
import ImagesItem from './ImagesItem'
import styles from './imagesLibrary.module.css'
import Loading from '../../components/Loading'
import { store } from '../../components/HOC/State'
import { translate } from '../translate'
import { axiosInstance } from '../../const/axiosInstance'

export default function ImagesLibrary({children}) {
  const [arr, setArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [updateed, setUpdated] = useState(false)
  const {offset, setOffset, setOffsetUpdated,selectedLang} = useContext(store)
  const limit = 20

  let lang = translate[selectedLang]

  useEffect(() => {
    setOffset(0)
    setUpdated(true)
  }, [])


  useEffect(() =>{
    (async() => {
      if(updateed){
        setLoading(true)
        const res = await axiosInstance.get(`/use/library?offset=${offset}&limit=${limit}`)
        if(res.data.status){
          setArr([...arr,...res.data.data])
          if(res.data.data.length == 20){
            setOffsetUpdated(false)
          }
        }else{
          setArr([...arr])
        }
        setLoading(false)
      }
    })()
  }, [offset, updateed])

  return (
    <div className={styles['images-library-main']}>
        <div className={styles['images-library-div']}>
            <p className={styles['images-library-category']}>{lang.tr_galery}</p>
            <div >
              {arr.length ? 
                <div className={styles['images-div']}>
                  {arr.map(el => <ImagesItem key={el._id} el={el}/>)}
                  {loading ? <Loading width={200}/> : ""}
                </div>
                :
                !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null
              }
              
            </div>
        </div>
    </div>
  )
}
