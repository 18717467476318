import React, { useContext, useEffect, useState } from 'react'
import styles from './expo.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpen, faLocationDot, faSquarePhone } from '@fortawesome/free-solid-svg-icons'
import ExpoLibrary from './ExpoLibrary'
import { store } from '../../components/HOC/State'
import Loading from '../../components/Loading'
import { translate } from '../translate'
import { axiosInstance } from '../../const/axiosInstance'

export default function ExpoPlaces() {
  const [arr, setArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [updateed, setUpdated] = useState(false)
  const {offset, setOffset, setOffsetUpdated, selectedLang} = useContext(store)
  const limit = 20

  let lang = translate[selectedLang]

  useEffect(() => {
    setOffset(0)
    setUpdated(true)
  }, [])

  useEffect(() =>{
    (async() => {
      if(updateed){
        setLoading(true)
        const res = await axiosInstance.get(`/use/expo/library?offset=${offset}&limit=${limit}`)
        if(res.data.status){
          setArr([...arr,...res.data.data])
          if(res.data.data.length === 20){
            setOffsetUpdated(false)
          }
        }else{
          setArr([...arr])
        }
        setLoading(false)
      }
    })()
  }, [offset, updateed])


  return (
    <div className={styles['main-expo-places']}>
        <div className={styles['contact-div']}>
            <div className={styles['contact-part-div']}>
                <FontAwesomeIcon className={styles['contact-part-icon']} icon={faSquarePhone} />
                <p className={styles['contact-part-title']}>{lang.tr_telephone}</p>
                <p>+37415 27 27 27</p>
            </div>
            <div className={styles['contact-part-div']}>
                <FontAwesomeIcon className={styles['contact-part-icon']} icon={faEnvelopeOpen} />
                <p className={styles['contact-part-title']}>{lang.tr_mail}</p>
                <a href='mailto: info@mergelyan.com'>info@mergelyan.com</a>
            </div>
            <div className={styles['contact-part-div']}>
                <FontAwesomeIcon className={styles['contact-part-icon']} icon={faLocationDot} />
                <p className={styles['contact-part-title']}>{lang.tr_address}</p>
                <p>{lang.tr_address2}</p>
            </div>
        </div>
        <div >
          {arr.length ? 
            <div className={styles['images-div']}>
                {arr.map(el => <ExpoLibrary key={el._id} el={el}/>)}
                {loading ? <Loading width={200}/> : ""}
            </div>
            : 
            !loading ? <p className='empty-message'>{lang.tr_noPublished}</p> : null 
          }
        </div>
    </div>
  )
}
