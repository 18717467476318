import React, { useState, useEffect } from "react"
import styles from './MobileMenu.module.css'
import headerStyles from '../Header/header.module.css'
import MenuItems from "../Menu/MenuItems"
import { useLocation } from "react-router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom"
import Search from "../Header/Search"
import Lang from "../Header/Lang/Lang"

const MobileMenu = ({name}) => {
    const [toggle, setToggle] = useState('0')
    const [rotate, setRotate] = useState(true)
    const location = useLocation()

    useEffect(() => {
        setToggle('0')
        setRotate(true)
    }, [location])

    function toggleHnadler() {
        setRotate(!rotate)

        if(!rotate) {
            setToggle('0')
        }else{
            setToggle('100vh')
        }
    }
    return (
        <div className={styles['main']}>
                <div className={styles['menu']} >
                    <Link to='/' className={styles['left-side']}>
                                <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                                <div className={headerStyles['name-div']}>
                                                <h1 className={headerStyles['name-h']}>{name?.[0]}</h1>
                                                <h1 className={`${headerStyles['name-h']} ${headerStyles['second-h2']}`}> {name?.[1]}</h1>
                                </div>
                    </Link>
                    
                    <div className={styles['right-side']}>
                        <Search />
                        <Lang />
                        <FontAwesomeIcon icon={faBars} className={!rotate  ? `${styles['menu-icon']} ${styles['rotate']}` : styles['menu-icon']}  onClick={toggleHnadler}/>
                        
                    </div>
                </div>
                <div className={styles['modal']} style={{height: toggle}}>
                    <MenuItems />
                    
                </div>    
            
        </div>

    )
}

export default MobileMenu