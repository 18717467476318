import React, {useContext, useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom';

import Search from './Search'
import Lang from './Lang/Lang'

import styles from './header.module.css'
import Menu from '../Menu/Menu';
import MobileMenu from '../MobileMenu/MobileMenu';
import { store } from '../HOC/State';
import { translate } from '../../Pages/translate';

export default function HeaderSide({atTop}) {
    const name = ['YCRDI', 'Secure Today, Safe Tomorrow']
    const {pathname} = useLocation()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const {selectedLang} = useContext(store)
        let lang = translate[selectedLang]

    useEffect( () => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [])
  return (
    windowWidth >= 1250 ?
    <div className={atTop && pathname === "/" ? styles['header-div'] : styles['header-div-main']}>
    {<Link to='/' className={styles['logo-div']}>
            <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
            <div className={styles['name-div']}>
                <h1 className={styles['name-h']}>YCRDI</h1>
                <h1 className={`${styles['name-h']} ${styles['second-h2']}`}>Secure Today, Safe Tomorrow</h1>
            </div>
        </Link>}
        
        <div className={styles['search-lang-end']}>
            <div className={styles['search-lang-div']}>
                <Menu/>
                <Search />
                <Lang/>
            </div>
        </div>

    </div>
    :
    <MobileMenu name={windowWidth <= 1250 && windowWidth >= 600 ? name : ''}/>

  )
}
